<template>
  <div class="dali-progress-wrapper">
    <div class="progress-steps">
      <div class="progress-step-item " :class="{actived:isActive(index)}" v-for="(step,index) in steps" :key="index">
        <div class="text">
          {{step}}
        </div>
        <img src="@/assets/progress-ring.png" class="ring" v-if="!isActive(index)">
        <img src="@/assets/progress-ring-actived.png" class="ring" v-else>
      </div>
    </div>
    <div class="progress-wrapper">
      <el-progress :percentage="progress" :show-text="false" :stroke-width="8"></el-progress>
    </div>

  </div>
</template>

<script>
export default {
  name: "DaliProgress",
  props:{
    startFrom: Number,
  },
  mounted() {
    setInterval(() => {
      const currentTimeStamp = new Date().getTime();
      this.progress = (currentTimeStamp - this.startFrom) / (3600 * 1000) * 100
      if (this.progress > 100 * 55 / 60) {
        this.progress = 100 * 55 / 60
      }
    }, 1000)
  },
  methods: {
    isActive(index) {
      if(index==0){
        return true
      }else {
        return this.progress/100>(index+1)/this.steps.length
      }

    }
  },
  data() {
    return {
      steps:['数据采集','统计分析','查看报告'],
      progress: 0
    }
  },
  computed: {

  }
}
</script>

<style lang="stylus" scoped>
.dali-progress-wrapper
  position relative
  display flex;
  flex-direction column
  padding 10px 20px
  .progress-steps
    width 320px
    position absolute
    display flex;
    flex-direction row
    justify-content space-between
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    z-index 1

    .progress-step-item
      display flex
      flex-direction column
      justify-content center
      align-items center

      .text
        position absolute
        top 1px
        white-space nowrap

      .ring
        margin-top 30px
        width 16px;
        height 17px

    .progress-step-item.actived
      font-weight: 600;
      color: #2F54EB;

  .progress-wrapper
    margin-top 35px
    width 320px


>>> .el-progress-bar__inner
  background rgba(47, 84, 235, 1)

>>> .el-progress-bar__outer
  background rgba(245, 245, 245, 1)
</style>
